<template>
  <div class="page-title">Sound Check</div>
  <div class="caution">
    Please check if you can hear the sample audio of the following text sentence.
    <p>
      Look at the picture. Read the words.<br>
      Which words goes with the picture?<br>
      Fill in the correct circles on your answer sheet.
    </p>
  </div>
  <div>
    <audio
      class="audio-control"
      id="test-audio"
      controls
      @play="startPlay"
      @ended="endPlay"
      type="audio/wav"
      src="https://prontest-cms-public.s3.ap-northeast-1.amazonaws.com/toefl/sound/sample001.wav"></audio>
    <div class="play-btn">
      <div
        @click="playAudio">
        <label v-if="isPlaying">Playing</label>
        <div v-else class="stop">
          <label>Play</label>
          <img src="@/assets/icon-play.svg"/>
        </div>
      </div>
    </div>
  </div>
  <div class="confirm-btns">
    <button
      @click="inaudible"
      class="btn-bg">
      How to fix it?
    </button>
    <button
      class="btn-bg"
      @click="next">
      OK
    </button>
  </div>
  <div
    v-if="displayDialog"
    class="modal">
    <div class="dialog">
      <div class="center font-size-42 mt-1">
        Please watch the video and adjust the volume.
      </div>
      <div class="video">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/-NT021mtL-Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <button
        @click="closeDialog"
        class="btn-bg center">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckAudio',
  props: {
  },
  data() {
    return {
      isPlaying: false,
      displayDialog: false,
    }
  },
  methods: {
    selectGrade(grade) {
      this.selectedGrade = grade
      this.step = 3
    },
    playAudio() {
      document.getElementById("test-audio").play()
    },
    startPlay() {
      this.isPlaying = true
    },
    endPlay() {
      this.isPlaying = false
    },
    next(v) {
      this.$emit('next', v)
    },
    inaudible() {
      this.displayDialog = true
    },
    closeDialog() {
      this.displayDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.audio-control {
  display: none;
}
.caution {
  background: white;
  border-radius: 30px;
  padding: 20px 50px;
  font-size: 20px;
  border: 1px gray solid;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  width: 80%;
  margin: auto;
  margin-top: 20px;
  p {
    text-align: center;
  }
}
.play-btn {
  padding: 10px 20px;
  background: white;
  width: 220px;
  font-size: 30px;
  margin: auto;
  border-bottom: 5px solid #707070;
  margin-top: 10vh;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  .stop {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img {
      margin-left: 10px;
    }
  }
}
.confirm-btns {
  margin: auto;
  display: flex;
  width: 80%;
  margin-top: 15vh;
  justify-content: space-evenly;
  .btn {
    font-size: 20px;
  }
}
.modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  .dialog {
    width: 90%;
    height: 90%;
    min-width: 800px;
    max-width: 1200px;
    background: white;
    border-radius: 50px;
    margin: auto;
    border: 1px solid #707070;
    box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  }
  .font-size-42 {
    font-size: 42px;
    font-weight: bold;
  }
  .video {
    width: 90%;
    margin: auto;
    height: 70%;
    margin-bottom: 20px;
  }
}
</style>

