<template>
  <div class="modal">
    <div class="dialog">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    onClick(v) {
      console.log('button clicked')
      this.$emit('clicked', v)
    },
    onClose() {
      this.$emit('close')
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  height: 100%;
}
.dialog {
  cursor: pointer;
  border: none;
  background: white;
  border-radius: 30px;
  padding: 5px 20px;
  max-width: 600px;
  margin: auto;
  padding: 1.5rem 8rem;
  border: 1px solid grey;
  font-size: 20px;
}
.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>