<template>
  <!--Lessonページ-->
  <div class="lesson-page" v-if="step === 1 && selectedTest">
    <div class="header">
      <div
        @click="backToSelect"
        class="btn-img">
        <img src="@/assets/icon-back.png" />
      </div>
    </div>
    <div class="page-title">
      <span
        v-if="selectedTest.type === 'lesson'">Lesson {{selectedTest.sort}}</span>
      <span
        v-else>Trial Examination</span>
    </div>
    <div class="lesson-name">
      {{selectedTest.title}}
    </div>
    <div class="time-limit">
      Time Target {{selectedTest.timeLimit}} minutes
    </div>
    <div class="lesson-image">
      <img :src="selectedTest.image" />
    </div>
    <div>
      <button
        class="btn-bg"
        @click="startCheckAudio()">
        Start
      </button>
    </div>
  </div>
  <div v-else-if="step === 2">
    <check-audio
    @next="audioOk"
    />
  </div>
  <!--アカウントダウン-->
  <div v-else-if="step === 3">
    <div
      class="count-title">
      The lesson is starting.
    </div>
    <div class="count-down">
      {{startCountDownSeconds}}
    </div>
  </div>
  <!--質問-->
  <div v-else-if="step === 4 && currentQuestion" class="exam">
    <div class="header">
      <div class="flex">
        <span class="caution">{{currentQuestionIndex}} / {{questions.length}} Questions</span>
      </div>
      <div class="flex vertical-center">
        <span>minutes</span>
        <span class="caution">{{remainingTime}}</span>
        <div class="progress ml-1">
          <progress max="100" :value="examProgress" />
        </div>
      </div>
      <div class="ml-1">
        <button
          class="btn-img font-size-20"
          @click="confirmDialog()">
          <img src="@/assets/icon-menu.png"/>
        </button>
      </div>
    </div>
    <div class="question" v-html="currentQuestion.title" />
    <div
      v-if="currentQuestion.audio && !initedAudioPlay && currentQuestionIndex == 1"
      class="manual-play"
      @click="playAudioManual">
        Click here if the sound does not play automatically
    </div>
    <div class="flex mt-2">
      <div class="question-image">
        <audio
          v-if="currentQuestion.audio"
          id="question-audio"
          type="audio/wav"
          class="hide"
          controls
          :src="currentQuestion.audio"></audio>
        <div v-if="currentQuestion.image">
          <img :src="currentQuestion.image" v-show="!loadingImage" @load="imageLoaded" />
          <span v-if="loadingImage">Loading</span>
        </div>
      </div>
      <div class="answer">
        <div
          class="answer-btn"
          :class="{'answer-btn-zoom1': zoom1(currentQuestion.answerA), 'answer-btn-zoom2': zoom2(currentQuestion.answerA), 'answer-btn-zoom3': zoom3(currentQuestion.answerA)}"
          @click="onAnswer(currentQuestion, 'a')">
          (A) {{currentQuestion.answerA}}
        </div>
        <div
          class="answer-btn"
          :class="{'answer-btn-zoom1': zoom1(currentQuestion.answerB), 'answer-btn-zoom2': zoom2(currentQuestion.answerB), 'answer-btn-zoom3': zoom3(currentQuestion.answerB)}"
          @click="onAnswer(currentQuestion, 'b')">
          (B) {{currentQuestion.answerB}}
        </div>
        <div
          class="answer-btn"
          :class="{'answer-btn-zoom1': zoom1(currentQuestion.answerC), 'answer-btn-zoom2': zoom2(currentQuestion.answerC), 'answer-btn-zoom3': zoom3(currentQuestion.answerC)}"
          @click="onAnswer(currentQuestion, 'c')">
          (C) {{currentQuestion.answerC}}
        </div>
        <div
          @click="onAnswer(currentQuestion, '0')"
          class="answer-btn unknown-btn">
          Skip
        </div>
      </div>
    </div>
    <Dialog
      v-if="displayDialog"
      @close="onClose()">
      <div class="confirm">
        It will not be graded. <br>
        Still want to return to the menu?
        <div class="btns">
          <button
            @click="backToMenu"
            class="btn-bg">
            Yes, I would
          </button>
          <button
            class="btn-bg"
            @click="onClose">
            No
          </button>
        </div>
      </div>
    </Dialog>
  </div>
  <div v-else-if="step === 5" class="complete">
    <h1 class="pt-4">
      The exam is over!
    </h1>
    <img src="@/assets/finish.svg" />
    <div>
      <button
        class="btn-bg"
        @click="displayResult">
        Finish
      </button>
    </div>
  </div>
  <div v-else-if="step === 6" class="complete">
    <h1 class="pt-4">
      Time’s up!
    </h1>
    <img src="@/assets/timeup.svg" />
    <div>
      <button
        class="btn-bg"
        @click="backToMenu">
        Menu
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Dialog from '../../components/atom/Dialog.vue'
import CheckAudio from '../../components/molecule/CheckAudio.vue'

export default {
  components: { Dialog, CheckAudio, },
  name: 'Exam',
  data() {
    return {
      step: 1,
      testHistoryId: null,
      startCountDownSeconds: 3,
      startCountDownIntervalId: null,
      currentQuestionIndex: null,
      examCountDownSeconds: 0,
      examCountDownIntervalId: null,
      displayDialog: false,
      questions: [],
      answers: [],
      loadingImage: false,
      initedAudioPlay: false,
    }
  },
  computed: {
    ...mapState(['selectedTest',]),
    currentQuestion() {
      return this.questions[this.currentQuestionIndex - 1]
    },
    remainingTime() {
      return parseInt(this.examCountDownSeconds / 60) + ':' + ('0' + this.examCountDownSeconds % 60).slice(-2)
    },
    examProgress() {
      return parseInt(this.examCountDownSeconds / (this.selectedTest.timeLimit * 60) * 100)
    },
    zoom1() {
      return answer => answer.length < 15
    },
    zoom2() {
      return answer => answer.length >= 15 && answer.length < 50
    },
    zoom3() {
      return answer => answer.length >= 50
    },
  },
  methods: {
    backToSelect() {
      this.$router.go(-1)
    },
    countDown() {
      this.startCountDownSeconds = this.startCountDownSeconds - 1
      if (Number(this.startCountDownSeconds) <= 0) {
        clearInterval(this.startCountDownIntervalId)
      }
    },
    startCheckAudio() {
      this.step++
    },
    startCountDown() {
      this.step = 3
      if (this.startCountDownIntervalId) return
      this.startCountDownIntervalId = setInterval(async () => this.countDown(), 1000)
    },
    async examCountDown() {
      this.examCountDownSeconds = this.examCountDownSeconds - 1
      if (Number(this.examCountDownSeconds) <= 0) {
        // 時間切れ、結果保存
        if (await this.saveResult() >= 0) this.step = 6
      }
    },
    async startExam() {
      // 質問取得
      this.questions = (await this.$api.get(`/questions?testId=${this.selectedTest.id}`)).data.questions
      if(this.questions.length < 1) {
        this.$store.commit('setMessage', '質問まだ登録されていません。')
        this.$router.push('/menu')
        return
      }
      this.step++
      clearInterval(this.startCountDownIntervalId)
      this.currentQuestionIndex = 1
      this.startCountDownIntervalId = null
      this.examCountDownSeconds = this.selectedTest.timeLimit * 60
      this.examCountDownIntervalId = setInterval(async () => this.examCountDown(), 1000)
    },
    confirmDialog() {
      this.displayDialog = true
    },
    onClose() {
      this.displayDialog = false
    },
    async onAnswer(question, answer) {
      this.answers.push({
        questionId: question.id,
        answer: answer
      })
      if (this.currentQuestionIndex >= this.questions.length) {
        // 全問終了、結果保存
        if ((await this.saveResult()) > 0) {
          this.step = 5
        } else {
          return
        }
      }
      const currentQuestion = question
      this.currentQuestionIndex++
      const nextQuestion = this.currentQuestion
      if (currentQuestion.image != nextQuestion.image) this.imageLoading()
    },
    async saveResult() {
      const savedNumber = (await this.$api.post('/test/complete', {
        testHistoryId: this.testHistoryId,
        answers: this.answers
      })).data.savedAnswerNumber
      clearInterval(this.examCountDownIntervalId)
      return savedNumber
    },
    async audioOk() {
      this.testHistoryId = (await this.$api.post(`/test/start?testId=${this.selectedTest.id}`, {})).data.testHistoryId
      this.step++
      this.startCountDown()
    },
    backToMenu() {
      clearInterval(this.examCountDownIntervalId)
      this.$router.push('/menu')
    },
    playAudio() {
      document.getElementById("question-audio").play()
    },
    playAudioManual() {
      this.initedAudioPlay = true
      this.playAudio()
    },
    imageLoading() {
      this.loadingImage = true
    },
    imageLoaded() {
      this.loadingImage = false
    },
    displayResult() {
      const type = this.selectedTest.type === 'lesson' ? 'lessonTest': 'trialTest'
      this.$router.push(`/result/${type}/${this.testHistoryId}`)
    }
  },
  watch: {
    startCountDownSeconds() {
      if (this.startCountDownSeconds == 0) {
        this.startExam()
      }
    },
    currentQuestionIndex() {
      if(this.currentQuestion?.audio) {
        setTimeout(() => this.playAudio(), 1500)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.lesson-page {
  .lesson-name {
    font-size: 30px;
  }
  .time-limit {
    font-size: 25px;
  }
  .lesson-image {
    img {
      height: 40vh;
    }
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.count-title {
  font-size: 42px;
  color: #707070;
  padding-top: 20vh;
}

.count-down {
  font-size: 150px;
  color: #707070;
  margin-top: 50px;
  font-weight: bold;
}

.exam {
  padding: 1vh 10px;
  color: #6b6565;
  min-height: 98vh;
  .header {
    display: flex;
    font-size: 23px;
    align-items: center;
    font-weight: 900;
    justify-content: space-between;
    span {
      padding: 2px 5px;
    }
    .caution {
      background: white;
      border-radius: 5px;
      border: 1px solid #707070;
    }
  }
  .question {
    margin-top: 20px;
    padding: 10px 30px;
    background: white;
    border-radius: 20px;
    font-weight: 800;
    font-size: 23px;
    text-align: left;
    border: 1px solid #707070;
  }
  .question-image {
    flex: 6;
    background: white;
    border-radius: 20px;
    padding: 10px 10px;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    img {
      max-height: 68vh;
      max-width: 100%;
    }
  }
  .answer {
    flex: 4;
    padding: 0 0 10px 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    .answer-btn {
      cursor: pointer;
      width: 85%;
      min-height: 48px;
      background: white;
      border: 1px solid gray;
      border-radius: 20px;
      color: #6b6565;
      text-align: left;
      padding: 0 5%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .answer-btn-zoom1 {
      font-size: 35px;
    }
    .answer-btn-zoom2 {
      font-size: 24px;
    }
    .answer-btn-zoom3 {
      font-size: 18px;
    }
    .unknown-btn {
      font-size: 35px;
      width: 45%;
    }
  }
  .confirm {
    min-width: 600px;
    font-size: 27px;
  }
  .btns {
    width: 100%;
    display: flex;
    margin-top: 10px;
    justify-content: space-evenly;
  }
  .vertical-center {
    align-items: center;
  }

  .manual-play {
    margin-bottom: -10px;
    background: #27529e;
    color: white;
    border-radius: 10px;
    width: 400px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
}

.complete {
  h1 {
    font-weight: normal;
    font-size: 54px;
  }
  img {
    height: 28vh;
    margin: 80px 0px;
  }
}

.progress {
  border-radius: 5px;
  width: 300px;
  height: 31px;
  progress {
    border-radius: 5px;
    width: 100%;
    height: 34px;
  }
  ::-webkit-progress-bar {
    background: white;
    border-radius: 5px;
    border: 1px solid #707070;
  }
  ::-webkit-progress-value {
    border-radius: 3px;
    background: #09e935;
  }
}
.font-size-20 {
  font-size: 20px;
}
</style>

